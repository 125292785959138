import axios from "axios";
import React, { useEffect, useState } from "react";
import ReactLoading from "react-loading";
import WordleWrapper from "../WordleGame";
import LoginSignUp from "./LoginSignUp";

const MainBox = () => {
  const token = window.localStorage.getItem("access_token");
  useEffect(() => {
    const validator = async () => {
      if (!token || !token.length) return setLoading(false);

      axios
        .get(`${process.env.REACT_APP_HOST_BE}/user/validate`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          if (res.status !== 200) return setLoading(false);

          setValid(true);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
    };
    validator();
  }, [token]);
  const [loading, setLoading] = useState(false);
  const [valid, setValid] = useState(false);
  return (
    <div className="main-container">
      {loading ? (
        <>
          <ReactLoading
            type="bars"
            color="#dddddd"
            height={"5%"}
            width={"5%"}
          />
        </>
      ) : valid ? (
        <WordleWrapper />
      ) : (
        <LoginSignUp {...{ setValid, setLoading }} />
      )}
    </div>
  );
};

export default MainBox;
