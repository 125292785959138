import React, { useState } from "react";

export default function Keypad({ usedKeys, handleKeyup }) {
  // eslint-disable-next-line
  const [letters, setLetters] = useState([
    { key: "q" },
    { key: "w" },
    { key: "e" },
    { key: "r" },
    { key: "t" },
    { key: "y" },
    { key: "u" },
    { key: "i" },
    { key: "o" },
    { key: "p" },
    { key: "a" },
    { key: "s" },
    { key: "d" },
    { key: "f" },
    { key: "g" },
    { key: "h" },
    { key: "j" },
    { key: "k" },
    { key: "l" },
    { key: "Backspace" },
    { key: "z" },
    { key: "x" },
    { key: "c" },
    { key: "v" },
    { key: "b" },
    { key: "n" },
    { key: "m" },
  ]);

  return (
    <>
      <div className="keypad">
        {letters &&
          letters.map((l) => {
            const color = usedKeys[l.key];
            return (
              <div
                key={l.key}
                className={color}
                onClick={() => {
                  handleKeyup({ key: l.key });
                }}
              >
                {l.key.length === 1 ? (
                  l.key
                ) : (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      height: "70%",
                      alignItems: "center",
                    }}
                  >
                    <svg
                      fill="#ff0000"
                      height="27px"
                      width="27px"
                      version="1.1"
                      id="Capa_1"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      viewBox="0 0 44.18 44.18"
                      xmlSpace="preserve"
                      stroke="#ff0000"
                      strokeWidth="0.0004418"
                      transform="rotate(0)"
                    >
                      <g
                        id="SVGRepo_tracerCarrier"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        stroke="#CCCCCC"
                        strokeWidth="0.55344000000000003"
                      ></g>
                      <g id="SVGRepo_iconCarrier">
                        <g>
                          <path d="M10.625,5.09L0,22.09l10.625,17H44.18v-34H10.625z M42.18,37.09H11.734l-9.375-15l9.375-15H42.18V37.09z"></path>{" "}
                          <polygon points="18.887,30.797 26.18,23.504 33.473,30.797 34.887,29.383 27.594,22.09 34.887,14.797 33.473,13.383 26.18,20.676 18.887,13.383 17.473,14.797 24.766,22.09 17.473,29.383 "></polygon>
                        </g>
                      </g>
                    </svg>
                  </div>
                )}
              </div>
            );
          })}
      </div>
      <div key="enter" className="container py-5 text-center">
        <button
          onClick={() => {
            handleKeyup({ key: "Enter" });
          }}
          className="button-37"
        >
          Submit Guess
        </button>
      </div>
    </>
  );
}
