import React from "react";

export default function Modal({ isCorrect, solution, turn }) {
  return (
    <div className="modal">
      {isCorrect ? (
        <div>
          <h1>You Win!</h1>
          <p className="solution">{solution}</p>
          <p>You found the solution in {turn} guesses :)</p>
          <p>
            <span
              style={{ cursor: "pointer" }}
              onClick={() => (window.location = "/")}
            >
              Click here to reload and get another word
            </span>
          </p>
        </div>
      ) : (
        <div>
          <h1>Big sad, you missed it</h1>
          <p>Better luck next time :)</p>
          <p>
            <span
              style={{ cursor: "pointer" }}
              onClick={() => (window.location = "/")}
            >
              Click here to reload and get another word
            </span>
          </p>
        </div>
      )}
    </div>
  );
}
