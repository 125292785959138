import React, { useState } from "react";

import { Box, Button, ButtonGroup, TextField } from "@mui/material";
import axios from "axios";

const LoginSignUp = (props) => {
  const [login, setLogin] = useState(false);
  return (
    <div
      style={{
        width: "100%",
        maxWidth: "550px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "20px",
      }}
    >
      <ButtonGroup
        color="success"
        style={{
          width: "80%",
          marginBottom: "20px",
        }}
      >
        <Button
          variant="outlined"
          fullWidth
          style={{ color: "white" }}
          className={`auth signup ${!login ? "active" : ""}`}
          onClick={() => setLogin(false)}
        >
          Signup
        </Button>
        <Button
          variant="outlined"
          fullWidth
          style={{ color: "white" }}
          className={`auth login ${login ? "active" : ""}`}
          onClick={() => setLogin(true)}
        >
          Login
        </Button>
      </ButtonGroup>
      {login ? <Login {...props} /> : <SignUp {...props} />}
    </div>
  );
};

const Login = (props) => {
  const [data, setData] = useState({ username: "", password: "" });
  const handleChange = (e) => {
    setData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };
  const handleSubmit = async () => {
    axios
      .get(
        `${process.env.REACT_APP_HOST_BE}/user/login?username=${data.username}&password=${data.password}`
      )
      .then((res) => {
        if (res.status !== 200)
          window.alert("Something went wrong. Pls contact Ars");
        const { data } = res;
        window.localStorage.setItem("access_token", data.token);
        props.setValid(true);
        props.setLoading(false);
      })
      .catch((err) => {
        window.alert(err.response.data.message);
      });
  };
  return (
    <Box flexDirection="column" alignItems="center" width="80%">
      <TextField
        variant="outlined"
        label="Username"
        margin="normal"
        color="success"
        name="username"
        onChange={handleChange}
        fullWidth
      />
      <TextField
        variant="outlined"
        label="Password"
        margin="normal"
        color="success"
        name="password"
        type="password"
        onChange={handleChange}
        fullWidth
      />
      <Button
        fullWidth
        variant="contained"
        color="success"
        style={{ fontSize: "105%", fontWeight: "700", marginTop: "15px" }}
        onClick={handleSubmit}
      >
        Login
      </Button>
    </Box>
  );
};

const SignUp = (props) => {
  const [data, setData] = useState({ username: "", email: "", password: "" });
  const handleChange = (e) => {
    setData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };
  const handleSubmit = async () => {
    axios
      .post(`${process.env.REACT_APP_HOST_BE}/user/create`, data)
      .then((res) => {
        if (res.status !== 200)
          window.alert("Something went wrong. Pls contact Ars");
        const { data } = res;
        window.localStorage.setItem("access_token", data.token);
        props.setValid(true);
        props.setLoading(false);
      })
      .catch((err) => {
        window.alert(err.response.data.message);
      });
  };
  return (
    <Box flexDirection="column" alignItems="center" width="80%">
      <TextField
        variant="outlined"
        label="Username"
        margin="normal"
        color="success"
        name="username"
        onChange={handleChange}
        fullWidth
      />
      <TextField
        variant="outlined"
        label="Email"
        margin="normal"
        color="success"
        name="email"
        onChange={handleChange}
        fullWidth
      />
      <TextField
        variant="outlined"
        label="Password"
        margin="normal"
        color="success"
        name="password"
        type="password"
        onChange={handleChange}
        fullWidth
      />
      <Button
        fullWidth
        variant="contained"
        color="success"
        style={{ fontSize: "100%", fontWeight: "600", marginTop: "15px" }}
        onClick={handleSubmit}
      >
        Create Account
      </Button>
    </Box>
  );
};

export default LoginSignUp;
