import axios from "axios";
import { useEffect, useState } from "react";
import Wordle from "./components/Wordle";

function WordleWrapper() {
  const [solution, setSolution] = useState(null);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_HOST_BE}/wordle/attach-new-word`, {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem(
            "access_token"
          )}`,
        },
      })
      .then((res) => res.data)
      .then((data) => {
        if (!data.success) return window.alert(data.message);
        return setSolution(data.word);
      });
  }, [setSolution]);

  return (
    <div className="App">
      <h1> CyberCon Wordle </h1> {solution && <Wordle solution={solution} />}{" "}
    </div>
  );
}

export default WordleWrapper;

/* 

data we need to track:
  -- solution
    -- 5 letter string, e.g. 'drain'
  -- past guesses
    -- an array of past guesses
    -- each past guess is an array of letter objects [{}, {}, {}, {}, {}]    -- CONFIRMATION OF THIS COMES FROM BACKEND
    -- each object represents a letter in the guess word {letter: 'a', color: 'yellow'}
  -- current guess
    -- string 'hello'
  -- keypad letters
    -- array of letter objects [{key: 'a', color: 'green'}, {}, {} ...]
  -- number of turns
    -- an integer 0 - 6

game process:
  -- entering words:
    -- user enters a letter & a square is filled with that letter
    -- when a user hits delete it deletes the previous letter
    -- when a user hits enter it submits the word  -- BE Interface
      -- if all squares are not filled with letters then the word is not submitted
      -- if that word has already been used in a prev guess then the word is not submitted
  -- checking submitted words: -- BE Interface
    -- each letter is checked to see if it matches to the solution
    -- each letter is assigned a color based on it's inclusion in the solution
      -- exact matches (correct position in the solution) are green
      -- partial matches (in the solution but not the correct position) are yellow
      -- none-matches (not in the solution at all) are grey
    -- the guess is added the grid with the correct colors
    -- the current guess moves to the next row
    -- the keypad letters are updated (colors)
  -- ending the game:
    -- when the guessed word fully matches the solution -- BE Interface
      -- modal to say 'well done'
    -- when the user runs out of guesses
      -- modal to say 'unlucky'

*/
