import { useState } from "react";
import axios from "axios";

const useWordle = (solution) => {
    const [turn, setTurn] = useState(0);
    const [currentGuess, setCurrentGuess] = useState("");
    const [guesses, setGuesses] = useState([...Array(5)]); // each guess is an array
    const [history, setHistory] = useState([]); // each guess is a string
    const [isCorrect, setIsCorrect] = useState(false);
    const [usedKeys, setUsedKeys] = useState({}); // {a: 'grey', b: 'green', c: 'yellow'} etc

    // format a guess into an array of letter objects
    // e.g. [{key: 'a', color: 'yellow'}]
    const formatGuess = async() => {
        const data = await axios.post(
            `${process.env.REACT_APP_HOST_BE}/wordle/attempt`, {
                word: solution,
                attempt: currentGuess,
            }, {
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem(
            "access_token"
          )}`,
                },
            }
        );
        return data.data.result;
    };

    // add a new guess to the guesses state
    // update the isCorrect state if the guess is correct
    // add one to the turn state
    const addNewGuess = (formattedGuess) => {
        var okay_count = 0;
        formattedGuess.forEach((k) => (okay_count += k.color === "green" ? 1 : 0));
        if (okay_count === 6) {
            setIsCorrect(true);
        }

        setGuesses((prevGuesses) => {
            let newGuesses = [...prevGuesses];
            newGuesses[turn] = formattedGuess;
            return newGuesses;
        });
        setHistory((prevHistory) => {
            return [...prevHistory, currentGuess];
        });
        setTurn((prevTurn) => {
            return prevTurn + 1;
        });
        setUsedKeys((prevUsedKeys) => {
            formattedGuess.forEach((l) => {
                const currentColor = prevUsedKeys[l.key];

                if (l.color === "green") {
                    prevUsedKeys[l.key] = "green";
                    return;
                }
                if (l.color === "yellow" && currentColor !== "green") {
                    prevUsedKeys[l.key] = "yellow";
                    return;
                }
                if (l.color === "grey" && currentColor !== ("green" || "yellow")) {
                    prevUsedKeys[l.key] = "grey";
                    return;
                }
            });

            return prevUsedKeys;
        });
        setCurrentGuess("");
    };

    // handle keyup event & track current guess
    // if user presses enter, add the new guess
    const handleKeyup = async({ key }) => {
        if (key === "Enter") {
            // only add guess if turn is less than 5
            if (turn > 4) {
                setIsCorrect(false);
                return;
            }
            // do not allow duplicate words
            if (history.includes(currentGuess)) {
                return;
            }
            // check word is 5 chars
            if (currentGuess.length !== 6) {
                return;
            }
            const formatted = await formatGuess();
            addNewGuess(formatted);
        }
        if (key === "Backspace") {
            setCurrentGuess((prev) => prev.slice(0, -1));
            return;
        }
        if (/^[A-Za-z]$/.test(key)) {
            if (currentGuess.length < 6) {
                setCurrentGuess((prev) => prev + key);
            }
        }
    };

    return { turn, currentGuess, guesses, isCorrect, usedKeys, handleKeyup };
};

export default useWordle;